import React, { useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useSnackbar } from 'notistack';
import Header from '../components/Header';
import Footer from '../components/Footer';

const AboutPage = () => {
  const { pathname } = useLocation();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    closeSnackbar();
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Header />
      <section className="pt-6 mt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-5 ms-auto me-auto text-center">
              <h2 className="mb-0 mt-4">About Us</h2>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-4 ms-auto my-auto ms-0 ms-md-5">
              <a role="button">
                <div className="card card-background tilt" data-tilt="">
                  <div className="full-background" style={{ backgroundImage: "url(assets/img/logo.png)" }}></div>
                  <div className="card-body pt-7 text-center" style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" }}>
                    <Link to="/contact">
                      <button type="button" className="btn btn-white btn-sm fw-bold mt-9">Get Started</button>
                    </Link>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-5 me-auto my-auto ms-0 ms-md-5">
              <div className="p-3 info-horizontal d-flex">
                <div>
                  <h5>Who We Are</h5>
                  <p>
                    We are a leading software company that provides innovative IT solutions and services to empower businesses and individuals to achieve their full potential.
                  </p>
                </div>
              </div>
              <div className="p-3 info-horizontal d-flex">
                <div>
                  <h5>Our Vision</h5>
                  <p>
                    Our vision is to be a leading provider of innovative IT solutions that empower businesses and individuals to achieve their full potential.
                  </p>
                </div>
              </div>
              <div className="p-3 info-horizontal d-flex">
                <div>
                  <h5>Our Approach</h5>
                  <p>
                    Our primary objective is to exceed our clients' expectations by tailoring our approach to meet their unique requirements.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AboutPage;