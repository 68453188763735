import React from 'react';
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <div>
            <section className="py-5 position-relative">
                <div className="container position-relative z-index-2">
                    <div className="row">
                        <div className="col-lg-5 col-md-8 m-auto text-start">
                            <h5 className="text-gradient text-primary mb-lg-0 mb-5">
                                Ready to start your project with our expert team and elevate your business to new heights.
                            </h5>
                        </div>
                        <div className="col-lg-6 m-auto">
                            <div className="d-flex float-end">
                                <Link to="/contact">
                                    <button type="button" className="btn bg-gradient-primary mb-0 ms-lg-3 ms-sm-2 mb-sm-0 mb-2 me-auto d-block fw-bold">Get Started</button>
                                </Link>
                                <Link to="/about">
                                    <button type="button" className="btn bg-gradient-dark mb-0 ms-lg-3 ms-sm-2 mb-sm-0 mb-2 me-auto d-block fw-bold">About Us</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Header;