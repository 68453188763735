import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useSnackbar } from 'notistack';
import Header from '../components/Header';
import Footer from '../components/Footer';

const TermsPage = () => {
  const { pathname } = useLocation();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    closeSnackbar();
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Header />
      <section className="pt-7 pt-lg-7 bg-gray-200">
        <div className="container">
          <div className="col-lg-8 col-md-10 mx-auto pb-5">
            <div className="card shadow-lg">
              <div className="card-header bg-gradient-dark p-5 position-relative">
                <h3 className="text-white mb-0">Terms &amp; Conditions</h3>
                <p className="text-white opacity-8 mb-4">Last modified: Jan 20 2024</p>
                <div className="position-absolute w-100 z-index-1 bottom-0 ms-n5">
                  <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" shapeRendering="auto" style={{ height: '7vh', minHeight: '50px' }}>
                    <defs>
                      <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                    </defs>
                    <g className="moving-waves">
                      <use href="#gentle-wave" x="48" y="-1" fill="rgba(255,255,255,0.40"></use>
                      <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                      <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                      <use href="#gentle-wave" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                      <use href="#gentle-wave" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                      <use href="#gentle-wave" x="48" y="16" fill="rgba(255,255,255,0.95"></use>
                    </g>
                  </svg>
                </div>
              </div>
              <div className="card-body p-5 pt-0" style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" }}>
                <h4 className="mt-5 mb-3">Introduction</h4>
                Welcome to Nexida Tech.<br />
                These Terms and Conditions govern your use of our website and services. By accessing or using the service, you agree to be bound by these terms.
                <h4 className="mt-5 mb-3">Use of Our Services</h4>
                <ul>
                  <li>Account Responsibilities: Users are responsible for maintaining the confidentiality of their account and password.</li>
                  <li>Acceptable Use Policy: Guidelines on what is considered acceptable use of your website and services.</li>
                </ul>
                <h4 className="mt-5 mb-3">Intellectual Property Rights</h4>
                All content on the website (e.g., text, graphics, logos) is the property of Nexida Tech and protected by copyright and other intellectual property laws. User-generated content must not infringe on the intellectual property rights of others.
                <h4 className="mt-5 mb-3">Third-Party Links and Content</h4>
                Your website may include links to third-party websites or content that are not owned or controlled by Nexida Tech.
                <h4 className="mt-5 mb-3">Termination of Use</h4>
                Nexida Tech reserves the right to terminate or suspend access to our services for any reason, including breach of these Terms and Conditions.
                <h4 className="mt-5 mb-3">Warranties and Limitation of Liability</h4>
                Services are provided "as is." Nexida Tech makes no warranties, express or implied. Limitation of liability for any damages arising out of or related to your use of the service.
                <h4 className="mt-5 mb-3">Indemnification</h4>
                Users agree to indemnify and hold harmless Nexida Tech from any claims, damages, or expenses arising from their use of the services.
                <h4 className="mt-5 mb-3">Changes to Terms and Conditions</h4>
                Nexida Tech reserves the right to modify these terms at any time. Users should periodically review the Terms and Conditions.
                <h4 className="mt-5 mb-3">Contact Information</h4>
                For any questions about these Terms and Conditions, contact us at info@nexidatech.com.
                <h4 className="mt-5 mb-3">Miscellaneous</h4>
                <ul>
                  <li>Severability: If any part of these Terms is held to be invalid or unenforceable, the remaining parts will continue to be valid and enforceable.</li>
                  <li>Entire Agreement: These Terms constitute the entire agreement between users and Nexida Tech regarding the use of the service.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default TermsPage;