import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div>
            <footer className="footer py-4 mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mb-4 mx-auto text-center">
                            <Link to="/privacy">
                                <a role="button" className="text-secondary me-xl-5 me-3 mb-sm-0 mb-2">
                                    Privacy Policy
                                </a>
                            </Link>
                            <Link to="/terms">
                                <a role="button" className="text-secondary me-xl-5 me-3 mb-sm-0 mb-2">
                                    Terms & Conditions
                                </a>
                            </Link>
                        </div>
                        <div className="col-lg-8 mx-auto text-center mb-4 mt-2">
                            {/* <a role="button" className="text-secondary me-xl-4 me-4">
                                <span className="text-lg fab fa-facebook"></span>
                            </a> */}
                            <a href="https://twitter.com/nexidatech" target="_blank" className="text-secondary me-xl-4 me-4">
                                <span className="text-lg fab fa-twitter"></span>
                            </a>
                            <a href="https://instagram.com/nexidatech" target="_blank" className="text-secondary me-xl-4 me-4">
                                <span className="text-lg fab fa-instagram"></span>
                            </a>
                            <a href="https://linkedin.com/company/nexidatech" target="_blank" className="text-secondary me-xl-4 me-4">
                                <span className="text-lg fab fa-linkedin"></span>
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 mx-auto text-center mt-1">
                            <p className="mb-0 text-secondary">
                                Copyright © {new Date().getFullYear()} Nexida Tech
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;