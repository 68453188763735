import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useSnackbar } from 'notistack';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PrivacyPage = () => {
  const { pathname } = useLocation();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    closeSnackbar();
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Header />
      <section className="pt-7 pt-lg-7 bg-gray-200">
        <div className="container">
          <div className="col-lg-8 col-md-10 mx-auto pb-5">
            <div className="card shadow-lg">
              <div className="card-header bg-gradient-dark p-5 position-relative">
                <h3 className="text-white mb-0">Privacy Policy</h3>
                <p className="text-white opacity-8 mb-4">Last modified: Jan 20 2024</p>
                <div className="position-absolute w-100 z-index-1 bottom-0 ms-n5">
                  <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" shapeRendering="auto" style={{ height: '7vh', minHeight: '50px' }}>
                    <defs>
                      <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                    </defs>
                    <g className="moving-waves">
                      <use href="#gentle-wave" x="48" y="-1" fill="rgba(255,255,255,0.40"></use>
                      <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                      <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                      <use href="#gentle-wave" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                      <use href="#gentle-wave" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                      <use href="#gentle-wave" x="48" y="16" fill="rgba(255,255,255,0.95"></use>
                    </g>
                  </svg>
                </div>
              </div>
              <div className="card-body p-5 pt-0" style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" }}>
                <h4 className="mt-5 mb-3">Introduction</h4>
                Welcome to Nexida Tech<br />
                This privacy policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.
                <h4 className="mt-5 mb-3">Information Collection</h4>
                <ul>
                  <li>Personal Information: Details collected when you register, make a purchase, or use our services (e.g., name, contact details, payment information).</li>
                  <li>Automatically Collected Information: Data collected via cookies and similar technologies (e.g., IP address, browser type, operating system).</li>
                  <li>Information from Third Parties: Information we may receive from other sources (e.g., social media platforms, market research).</li>
                  <li>Redistribute content from Creative Tim</li>
                </ul>
                <h4 className="mt-5 mb-3">Use of Information</h4>
                The information collected will be used for purposes such as:<br />
                <ul>
                  <li>Providing and maintaining our services.</li>
                  <li>Enhancing user experience.</li>
                  <li>Communicating with users.</li>
                  <li>Marketing and advertising.</li>
                  <li>Compliance and legal purposes.</li>
                </ul>
                <h4 className="mt-5 mb-3">Information Sharing</h4>
                We may share information with:<br />
                <ul>
                  <li>Service providers and affiliates.</li>
                  <li>Business transfers (e.g., during a merger or acquisition).</li>
                  <li>Legal obligations and rights.</li>
                </ul>
                <h4 className="mt-5 mb-3">International Data Transfers</h4>
                Information may be transferred to, and maintained on, computers located outside of your state, province, country, or other governmental jurisdiction.
                <h4 className="mt-5 mb-3">Data Security</h4>
                We implement various security measures to maintain the safety of your personal information.
                <h4 className="mt-5 mb-3">Your Privacy Rights</h4>
                Depending on your jurisdiction, you may have certain rights regarding your personal data (e.g., access, correction, deletion).
                <h4 className="mt-5 mb-3">Third-Party Websites</h4>
                Our website may contain links to third-party websites that are not operated by us.
                <h4 className="mt-5 mb-3">Changes to This Privacy Policy</h4>
                We reserve the right to make changes to this privacy policy at any time.
                <h4 className="mt-5 mb-3">Contact Us</h4>
                If you have any questions or suggestions about our privacy policy, please contact us at info@nexidatech.com.
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PrivacyPage;