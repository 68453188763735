import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useSnackbar } from 'notistack';
import axios from 'axios';
import Header from '../components/Header';
import Footer from '../components/Footer';

const ContactPage = () => {
  const { pathname } = useLocation();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isValidEmail = email => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!formData.firstName || !formData.lastName || !formData.email || !formData.message) {
      closeSnackbar();
      enqueueSnackbar('All fields are required.', { variant: 'error' });
      setIsLoading(false);
      return;
    }

    if (!isValidEmail(formData.email)) {
      closeSnackbar();
      enqueueSnackbar('Please enter a valid email address.', { variant: 'error' });
      setIsLoading(false);
      return;
    }

    if (!acceptedTerms) {
      closeSnackbar();
      enqueueSnackbar('You must accept the terms and conditions.', { variant: 'error' });
      setIsLoading(false);
      return;
    }

    try {
      const apiKey = process.env.REACT_APP_API_KEY;

      axios.post('https://api.nexidatech.com/api/send_contact/', {
        name: formData.firstName + ' ' + formData.lastName,
        email: formData.email,
        message: formData.message
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-API-KEY': apiKey
        }
      })
        .then(function (response) {
          if (response.data.status === true) {
            closeSnackbar();
            enqueueSnackbar('Message sent successfully!', { variant: 'success' });
            setFormData({ firstName: '', lastName: '', email: '', message: '' });
            setAcceptedTerms(false);
            setIsLoading(false);
          } else {
            closeSnackbar();
            enqueueSnackbar('Failed to send message.', { variant: 'error' });
            setIsLoading(false);
          }
        })
        .catch(function (error) {
          closeSnackbar();
          enqueueSnackbar('Failed to send message.', { variant: 'error' });
          setIsLoading(false);
        });
    } catch (error) {
      closeSnackbar();
      enqueueSnackbar('Failed to send message.', { variant: 'error' });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    closeSnackbar();
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      {isLoading ? <div className="loading">Loading&#8230;</div> : ''}
      <Header />
      <section>
        <div className="page-header min-vh-100 py-4">
          <div className="position-absolute fixed-top ms-auto w-50 h-100 rounded-3 z-index-0 d-none d-sm-none d-md-block me-n4" style={{ backgroundImage: "url(assets/img/contact_bg.jpg)", backgroundSize: "cover", backgroundPosition: "center" }}>
          </div>
          <div className="container pt-6">
            <div className="row">
              <div className="col-lg-7 d-flex justify-content-center flex-column">
                <div className="card card-body d-flex justify-content-center shadow-lg p-sm-5 blur align-items-center">
                  <h3 className="text-center">Contact us</h3>
                  <form id="contact-form" method="post" autoComplete="off" onSubmit={handleSubmit}>
                    <div className="card-body" style={{ fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" }}>
                      <div className="row">
                        <div className="col-md-6">
                          <label>First Name</label>
                          <div className="input-group mb-4">
                            <input type="text" className="form-control" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} disabled={isLoading} />
                          </div>
                        </div>
                        <div className="col-md-6 ps-2">
                          <label>Last Name</label>
                          <div className="input-group">
                            <input type="text" className="form-control" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} disabled={isLoading} />
                          </div>
                        </div>
                      </div>
                      <div className="mb-4">
                        <label>Email Address</label>
                        <div className="input-group">
                          <input type="text" className="form-control" name="email" placeholder="Email" value={formData.email} onChange={handleChange} disabled={isLoading} />
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <label>Your message</label>
                        <textarea className="form-control" name="message" id="message" rows="4" placeholder="Message" value={formData.message} onChange={handleChange} disabled={isLoading}></textarea>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-check form-switch mb-4">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                              checked={acceptedTerms}
                              onChange={() => setAcceptedTerms(!acceptedTerms)}
                              disabled={isLoading} />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">I agree to the <Link to="/terms"><a role="button" className="text-dark"><u>Terms and Conditions</u></a></Link>.</label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <button type="submit" className="btn bg-gradient-dark w-100" disabled={isLoading}>Send Message</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-3 d-flex justify-content-center flex-column mt-4 mt-lg-0 ms-auto">
                <div className="row blur border-radius-lg shadow-blur">
                  <div className="col-12 position-relative my-auto">
                    <div className="p-3 text-center">
                      <div className="icon icon-shape bg-gradient-primary shadow text-center rounded-circle">
                        <svg className="mt-3" width="15px" height="15px" viewBox="0 0 45 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                          <title>map-big</title>
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g transform="translate(-2321.000000, -593.000000)" fill="#FFFFFF" fillRule="nonzero">
                              <g transform="translate(1716.000000, 291.000000)">
                                <g id="map-big" transform="translate(605.000000, 302.000000)">
                                  <polygon points="31.875 0.71625 24.375 4.46625 24.375 40.53375 31.875 36.78375"></polygon>
                                  <polygon points="20.625 4.46625 13.125 0.71625 13.125 36.78375 20.625 40.53375"></polygon>
                                  <path d="M9.375,0.81375 L0.909375,5.893125 C0.346875,6.230625 0,6.84 0,7.5 L0,43.125 L9.375,37.06125 L9.375,0.81375 Z" opacity="0.70186942"></path>
                                  <path d="M44.090625,5.893125 L35.625,0.81375 L35.625,37.06125 L45,43.125 L45,7.5 C45,6.84 44.653125,6.230625 44.090625,5.893125 Z" opacity="0.70186942"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                      <h5 className="mt-3 mb-1">Address</h5>
                      <p className="mb-0">Shahrah-e-Faisal, Karachi, Pakistan</p>
                      <hr className="horizontal dark" />
                    </div>
                  </div>
                  <div className="col-12 position-relative my-auto">
                    <div className="p-3 text-center">
                      <div className="icon icon-shape bg-gradient-primary shadow text-center rounded-circle">
                        <svg className="mt-3" width="15px" height="15px" viewBox="0 0 40 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                          <title>document</title>
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g transform="translate(-1870.000000, -591.000000)" fill="#FFFFFF" fillRule="nonzero">
                              <g transform="translate(1716.000000, 291.000000)">
                                <g transform="translate(154.000000, 300.000000)">
                                  <path d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z" opacity="0.603585379"></path>
                                  <path d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                      <h5 className="mt-3 mb-1">Email</h5>
                      <p className="mb-0">info@nexidatech.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ContactPage;