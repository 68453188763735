import React from 'react';
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <div>
            <div className="container position-sticky z-index-sticky top-0">
                <div className="row">
                    <div className="col-12">
                        <nav className="navbar navbar-expand-lg  blur blur-rounded top-0  z-index-3 shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
                            <div className="container-fluid px-0">
                                <Link to="/">
                                    <a className="navbar-brand font-weight-bolder ms-sm-3">
                                        Nexida Tech
                                    </a>
                                </Link>
                                <Link to="/contact">
                                    <a className="btn btn-sm  bg-gradient-primary  btn-round fw-bold mb-0 ms-auto d-lg-none d-block">Contact Us</a>
                                </Link>
                                <button className="navbar-toggler shadow-none border-0 ms-md-2" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon mt-2">
                                        <span className="navbar-toggler-bar bar1"></span>
                                        <span className="navbar-toggler-bar bar2"></span>
                                        <span className="navbar-toggler-bar bar3"></span>
                                    </span>
                                </button>
                                <div className="collapse navbar-collapse w-100 pt-3 pb-2 py-lg-0" id="navigation">
                                    <ul className="navbar-nav navbar-nav-hover mx-auto">
                                        <li className="nav-item mx-2">
                                            <Link to="/about">
                                                <a className="nav-link ps-2" role="button">
                                                    <span>About Us</span>
                                                </a>
                                            </Link>
                                        </li>
                                        <li className="nav-item dropdown dropdown-hover mx-2">
                                            <a role="button"
                                                className="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                                                id="dropdownMenuPages" data-bs-toggle="dropdown" aria-expanded="false">
                                                Services
                                                <img src="../assets/img/down-arrow-dark.svg" alt="down-arrow" className="arrow ms-1" />
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-animation dropdown-xl p-3 border-radius-xl mt-0 mt-lg-3"
                                                aria-labelledby="dropdownMenuPages">
                                                <div className="row d-none d-lg-block">
                                                    <div className="col-12 px-4 py-2">
                                                        <div className="row">
                                                            <div className="col-4 position-relative">
                                                                <div className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center px-0">
                                                                    Web Development
                                                                </div>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Custom SaaS Platforms</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>E-Commerce Platforms</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>CRM Solutions</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>CMS Development</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Custom Web Apps</span>
                                                                </a>
                                                                <hr className="vertical dark" />
                                                            </div>
                                                            <div className="col-4 position-relative">
                                                                <div className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center px-0">
                                                                    Mobile Development
                                                                </div>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>iOS/Android Apps</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Cross-Platform Apps</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Mobile Commerce</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>App Maintenance</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>App Optimization</span>
                                                                </a>
                                                                <hr className="vertical dark" />
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center px-0">
                                                                    Design
                                                                </div>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>UI/UX Design</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Responsive Design</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Branding & Identity</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Graphic Design</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Motion Graphics</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-lg-none">
                                                    <div className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center px-0">
                                                        Web Development
                                                    </div>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Custom SaaS Platforms
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        E-Commerce Platforms
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        CRM Solutions
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        CMS Development
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Custom Web Apps
                                                    </a>
                                                    <div className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center px-0 mt-3">
                                                        Mobile Development
                                                    </div>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        iOS/Android Apps
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Cross-Platform Apps
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Mobile Commerce
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        App Maintenance
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        App Optimization
                                                    </a>
                                                    <div className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center px-0 mt-3">
                                                        Design
                                                    </div>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        UI/UX Design
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Responsive Design
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Branding & Identity
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Graphic Design
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Motion Graphics
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                        {/* <li class="nav-item dropdown dropdown-hover mx-2">
                                            <a role="button" class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center" id="dropdownMenuDocs" data-bs-toggle="dropdown" aria-expanded="false">
                                                Products
                                                <img src="./assets/img/down-arrow-dark.svg" alt="down-arrow" class="arrow ms-1" />
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-animation dropdown-lg mt-0 mt-lg-3 p-3 border-radius-lg" aria-labelledby="dropdownMenuDocs">
                                                <div class="d-none d-lg-block">
                                                    <ul class="list-group">
                                                        <li class="nav-item list-group-item border-0 p-0">
                                                            <a class="dropdown-item py-2 ps-3 border-radius-md" href="https://www.creative-tim.com/learning-lab/bootstrap/overview/soft-ui-design-system?_ga=2.27451515.612186160.1705867996-1824657507.1705441237">
                                                                <div class="d-flex">
                                                                    <div class="icon h-10 me-3 d-flex mt-1">
                                                                        <svg class="text-secondary" width="16px" height="16px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                                            <title>spaceship</title>
                                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                <g transform="translate(-1720.000000, -592.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                                                    <g transform="translate(1716.000000, 291.000000)">
                                                                                        <g transform="translate(4.000000, 301.000000)">
                                                                                            <path class="color-background" d="M39.3,0.706666667 C38.9660984,0.370464027 38.5048767,0.192278529 38.0316667,0.216666667 C14.6516667,1.43666667 6.015,22.2633333 5.93166667,22.4733333 C5.68236407,23.0926189 5.82664679,23.8009159 6.29833333,24.2733333 L15.7266667,33.7016667 C16.2013871,34.1756798 16.9140329,34.3188658 17.535,34.065 C17.7433333,33.98 38.4583333,25.2466667 39.7816667,1.97666667 C39.8087196,1.50414529 39.6335979,1.04240574 39.3,0.706666667 Z M25.69,19.0233333 C24.7367525,19.9768687 23.3029475,20.2622391 22.0572426,19.7463614 C20.8115377,19.2304837 19.9992882,18.0149658 19.9992882,16.6666667 C19.9992882,15.3183676 20.8115377,14.1028496 22.0572426,13.5869719 C23.3029475,13.0710943 24.7367525,13.3564646 25.69,14.31 C26.9912731,15.6116662 26.9912731,17.7216672 25.69,19.0233333 L25.69,19.0233333 Z">
                                                                                            </path>
                                                                                            <path class="color-background" d="M1.855,31.4066667 C3.05106558,30.2024182 4.79973884,29.7296005 6.43969145,30.1670277 C8.07964407,30.6044549 9.36054508,31.8853559 9.7979723,33.5253085 C10.2353995,35.1652612 9.76258177,36.9139344 8.55833333,38.11 C6.70666667,39.9616667 0,40 0,40 C0,40 0,33.2566667 1.855,31.4066667 Z">
                                                                                            </path>
                                                                                            <path class="color-background" d="M17.2616667,3.90166667 C12.4943643,3.07192755 7.62174065,4.61673894 4.20333333,8.04166667 C3.31200265,8.94126033 2.53706177,9.94913142 1.89666667,11.0416667 C1.5109569,11.6966059 1.61721591,12.5295394 2.155,13.0666667 L5.47,16.3833333 C8.55036617,11.4946947 12.5559074,7.25476565 17.2616667,3.90166667 L17.2616667,3.90166667 Z" opacity="0.598539807"></path>
                                                                                            <path class="color-background" d="M36.0983333,22.7383333 C36.9280725,27.5056357 35.3832611,32.3782594 31.9583333,35.7966667 C31.0587397,36.6879974 30.0508686,37.4629382 28.9583333,38.1033333 C28.3033941,38.4890431 27.4704606,38.3827841 26.9333333,37.845 L23.6166667,34.53 C28.5053053,31.4496338 32.7452344,27.4440926 36.0983333,22.7383333 L36.0983333,22.7383333 Z" opacity="0.598539807"></path>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                    <div>
                                                                        <h6 class="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Point of Sale</h6>
                                                                        <span class="text-sm">Seamless Inventory Management, Unified Multiple Outlets, Enhanced Sales Analytics</span>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li class="nav-item list-group-item border-0 p-0">
                                                            <a class="dropdown-item py-2 ps-3 border-radius-md" href=" https://www.creative-tim.com/learning-lab/bootstrap/colors/soft-ui-design-system ">
                                                                <div class="d-flex">
                                                                    <div class="icon h-10 me-3 d-flex mt-1">
                                                                        <svg class="text-secondary" width="16px" height="16px" viewBox="0 0 40 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                                            <title>document</title>
                                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                <g transform="translate(-1870.000000, -591.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                                                    <g transform="translate(1716.000000, 291.000000)">
                                                                                        <g transform="translate(154.000000, 300.000000)">
                                                                                            <path class="color-background" d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z" opacity="0.603585379"></path>
                                                                                            <path class="color-background" d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z">
                                                                                            </path>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                    <div>
                                                                        <h6 class="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Automations</h6>
                                                                        <span class="text-sm">LinkedIn Profile Scraper, LinkedIn Search Export, Sales Navigator Scraper</span>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li class="nav-item list-group-item border-0 p-0">
                                                            <a class="dropdown-item py-2 ps-3 border-radius-md" href=" https://www.creative-tim.com/learning-lab/bootstrap/alerts/soft-ui-design-system ">
                                                                <div class="d-flex">
                                                                    <div class="icon h-10 me-3 d-flex mt-1">
                                                                        <svg class="text-secondary" width="16px" height="16px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                                            <title>box-3d-50</title>
                                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                <g transform="translate(-2319.000000, -291.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                                                    <g transform="translate(1716.000000, 291.000000)">
                                                                                        <g transform="translate(603.000000, 0.000000)">
                                                                                            <path class="color-background" d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z">
                                                                                            </path>
                                                                                            <path class="color-background" d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z" opacity="0.7"></path>
                                                                                            <path class="color-background" d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z" opacity="0.7"></path>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                    <div>
                                                                        <h6 class="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Email Verifications</h6>
                                                                        <span class="text-sm">Precision Email Finder, Email Existence Verifier</span>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="row d-lg-none">
                                                    <div class="col-md-12 g-0">
                                                        <a class="dropdown-item py-2 ps-3 border-radius-md" href="./pages/about-us.html">
                                                            <div class="d-flex">
                                                                <div class="icon h-10 me-3 d-flex mt-1">
                                                                    <svg class="text-secondary" width="16px" height="16px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                                        <title>spaceship</title>
                                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g transform="translate(-1720.000000, -592.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                                                <g transform="translate(1716.000000, 291.000000)">
                                                                                    <g transform="translate(4.000000, 301.000000)">
                                                                                        <path class="color-background" d="M39.3,0.706666667 C38.9660984,0.370464027 38.5048767,0.192278529 38.0316667,0.216666667 C14.6516667,1.43666667 6.015,22.2633333 5.93166667,22.4733333 C5.68236407,23.0926189 5.82664679,23.8009159 6.29833333,24.2733333 L15.7266667,33.7016667 C16.2013871,34.1756798 16.9140329,34.3188658 17.535,34.065 C17.7433333,33.98 38.4583333,25.2466667 39.7816667,1.97666667 C39.8087196,1.50414529 39.6335979,1.04240574 39.3,0.706666667 Z M25.69,19.0233333 C24.7367525,19.9768687 23.3029475,20.2622391 22.0572426,19.7463614 C20.8115377,19.2304837 19.9992882,18.0149658 19.9992882,16.6666667 C19.9992882,15.3183676 20.8115377,14.1028496 22.0572426,13.5869719 C23.3029475,13.0710943 24.7367525,13.3564646 25.69,14.31 C26.9912731,15.6116662 26.9912731,17.7216672 25.69,19.0233333 L25.69,19.0233333 Z">
                                                                                        </path>
                                                                                        <path class="color-background" d="M1.855,31.4066667 C3.05106558,30.2024182 4.79973884,29.7296005 6.43969145,30.1670277 C8.07964407,30.6044549 9.36054508,31.8853559 9.7979723,33.5253085 C10.2353995,35.1652612 9.76258177,36.9139344 8.55833333,38.11 C6.70666667,39.9616667 0,40 0,40 C0,40 0,33.2566667 1.855,31.4066667 Z">
                                                                                        </path>
                                                                                        <path class="color-background" d="M17.2616667,3.90166667 C12.4943643,3.07192755 7.62174065,4.61673894 4.20333333,8.04166667 C3.31200265,8.94126033 2.53706177,9.94913142 1.89666667,11.0416667 C1.5109569,11.6966059 1.61721591,12.5295394 2.155,13.0666667 L5.47,16.3833333 C8.55036617,11.4946947 12.5559074,7.25476565 17.2616667,3.90166667 L17.2616667,3.90166667 Z" opacity="0.598539807"></path>
                                                                                        <path class="color-background" d="M36.0983333,22.7383333 C36.9280725,27.5056357 35.3832611,32.3782594 31.9583333,35.7966667 C31.0587397,36.6879974 30.0508686,37.4629382 28.9583333,38.1033333 C28.3033941,38.4890431 27.4704606,38.3827841 26.9333333,37.845 L23.6166667,34.53 C28.5053053,31.4496338 32.7452344,27.4440926 36.0983333,22.7383333 L36.0983333,22.7383333 Z" opacity="0.598539807"></path>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                                <div>
                                                                    <h6 class="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Point of Sale</h6>
                                                                    <span class="text-sm">seamless Inventory Management, Unified Multiple Outlets, Enhanced Sales Analytics</span>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a class="dropdown-item py-2 ps-3 border-radius-md" href="./pages/about-us.html">
                                                            <div class="d-flex">
                                                                <div class="icon h-10 me-3 d-flex mt-1">
                                                                    <svg class="text-secondary" width="16px" height="16px" viewBox="0 0 40 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                                        <title>document</title>
                                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g transform="translate(-1870.000000, -591.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                                                <g transform="translate(1716.000000, 291.000000)">
                                                                                    <g transform="translate(154.000000, 300.000000)">
                                                                                        <path class="color-background" d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z" opacity="0.603585379"></path>
                                                                                        <path class="color-background" d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z">
                                                                                        </path>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                                <div>
                                                                    <h6 class="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Automations</h6>
                                                                    <span class="text-sm">LinkedIn Profile Scraper, LinkedIn Search Export, Sales Navigator Scraper</span>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a class="dropdown-item py-2 ps-3 border-radius-md" href="./pages/about-us.html">
                                                            <div class="d-flex">
                                                                <div class="icon h-10 me-3 d-flex mt-1">
                                                                    <svg class="text-secondary" width="16px" height="16px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                                        <title>box-3d-50</title>
                                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g transform="translate(-2319.000000, -291.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                                                <g transform="translate(1716.000000, 291.000000)">
                                                                                    <g transform="translate(603.000000, 0.000000)">
                                                                                        <path class="color-background" d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z">
                                                                                        </path>
                                                                                        <path class="color-background" d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z" opacity="0.7"></path>
                                                                                        <path class="color-background" d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z" opacity="0.7"></path>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                                <div>
                                                                    <h6 class="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Email Verifications</h6>
                                                                    <span class="text-sm">Precision Email Finder, Email Existence Verifier</span>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> */}
                                        <li className="nav-item dropdown dropdown-hover mx-2">
                                            <a role="button"
                                                className="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                                                id="dropdownMenuPages" data-bs-toggle="dropdown" aria-expanded="false">
                                                Technologies
                                                <img src="../assets/img/down-arrow-dark.svg" alt="down-arrow" className="arrow ms-1" />
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-animation dropdown-xl p-3 border-radius-xl mt-0 mt-lg-3"
                                                aria-labelledby="dropdownMenuPages">
                                                <div className="row d-none d-lg-block">
                                                    <div className="col-12 px-4 py-2">
                                                        <div className="row">
                                                            <div className="col-4 position-relative">
                                                                <div className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center px-0">
                                                                    Back-end
                                                                </div>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Laravel</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Node.js</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Django</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>CodeIgniter</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Flask</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Python</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>JavaScript</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>TypeScript</span>
                                                                </a>
                                                                <hr className="vertical dark" />
                                                            </div>
                                                            <div className="col-4 position-relative">
                                                                <div className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center px-0">
                                                                    Front-end
                                                                </div>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>React.js</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Vue.js</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>AngularJS</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Material Design</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>HTML</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>CSS</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>JavaScript</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Bootstrap</span>
                                                                </a>
                                                                <hr className="vertical dark" />
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center px-0">
                                                                    CMS
                                                                </div>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>WordPress</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>WooCommerce</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Shopify</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Magento</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>BigCommerce</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Joomla</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Drupal</span>
                                                                </a>
                                                            </div>
                                                            <div className="col-4 position-relative">
                                                                <div className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center px-0">
                                                                    Mobile App
                                                                </div>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Flutter</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>React Native</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Swift</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Objective-C</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Kotlin</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Java</span>
                                                                </a>
                                                                <hr className="vertical dark" />
                                                            </div>
                                                            <div className="col-4 position-relative">
                                                                <div className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center px-0">
                                                                    Database
                                                                </div>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>MySQL</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>PostgreSQL</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>MongoDB</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>SQLite</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Microsoft SQL Server</span>
                                                                </a>
                                                                <hr className="vertical dark" />
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center px-0">
                                                                    DevOps
                                                                </div>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>AWS</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Google App Engine</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Azure</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>Docker & Kubernetes</span>
                                                                </a>
                                                                <a role="button" className="dropdown-item border-radius-md">
                                                                    <span>CI/CD</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-lg-none">
                                                    <div className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center px-0">
                                                        Back-end
                                                    </div>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Laravel
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Node.js
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Django
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        CodeIgniter
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Flask
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Python
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        TypeScript
                                                    </a>
                                                    <div className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center px-0 mt-3">
                                                        Front-end
                                                    </div>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        React.js
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Vue.js
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        AngularJS
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Material Design
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        HTML5
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        CSS3
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Bootstrap
                                                    </a>
                                                    <div className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center px-0 mt-3">
                                                        CMS
                                                    </div>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        WordPress
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        WooCommerce
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Shopify
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Magento
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        BigCommerce
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Joomla
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Drupal
                                                    </a>
                                                    <div className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center px-0 mt-3">
                                                        Mobile App
                                                    </div>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Flutter
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        React Native
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Swift
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Objective-C
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Kotlin
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Java
                                                    </a>
                                                    <div className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center px-0 mt-3">
                                                        Database
                                                    </div>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        MySQL
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        PostgreSQL
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        MongoDB
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        SQLite
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Microsoft SQL Server
                                                    </a>
                                                    <div className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center px-0 mt-3">
                                                        DevOps
                                                    </div>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        AWS
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Google App Engine
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Azure
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        Docker & Kubernetes
                                                    </a>
                                                    <a role="button" className="dropdown-item border-radius-md">
                                                        CI/CD
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="navbar-nav d-lg-block d-none">
                                        <li className="nav-item">
                                            <Link to="/contact">
                                                <a className="btn btn-sm bg-gradient-primary btn-round fw-bold mb-0 me-1">Contact Us</a>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;